import './App.css';
import { BrowserRouter as Router } from 'react-router-dom'
import AnimatedRoutes from './Components/AnimatedRoutes';
import Nav from './Components/Nav';

function App() {
  return (
    <div className="App">
    <Router>
       <Nav />
        <AnimatedRoutes />
      </Router>
    
    </div>
  );
}

export default App;
