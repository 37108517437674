import { useState } from "react";

const Form = () => {

    const [name, setName] = useState("");
    const [num, setNum] = useState("");
    const [msg, setMsg] = useState("");

    return (
        <div className="formDiv">
                <h1>Send us a Message!</h1>
                    <form action="https://formsubmit.co/9cdd77412a56682fd347bb45caaa7b7d" method="POST">
                                        <div className='row'>
                                        <label htmlFor="name">Your Name: </label>
                                        <br/>
                                        <input id="name" name="name" type="text" placeholder="First & Last Name" required 
                                                    onChange={(e) => setName(e.target.value)}
                                                    value= {name}
                                        />
                                        <input type="text" name="_honey" style={{display: "none"}}></input>
                                        <input type="hidden" name="_cc" value="9313092581@txt.att.net"></input>
                                        <input type="hidden" name="_next" value="https://www.nirvanarvpark.com/thankyou"></input>

                                        </div>

                                        <br/>
                                        <div className='row'>
                                            <label htmlFor="phone">Your Phone Number: </label>
                                            <br/>
                                            <input id="phone" type="tel" placeholder="Format: 555-333-1234" name="phone" required
                                                        minlength="10" maxlength="14"
                                                        onChange={(e) => setNum(e.target.value)}
                                                        value= {num}
                                                        />

                                        </div>
                                        <br/>
                                        
                                        <div className='row'>
                                        <label htmlFor="message">Message: </label>
                                        <br/>
                                        <textarea id= "message" name= "message" placeholder="Write your message here. Max 140 characters."
                                            required rows="5" cols="21" 
                                            maxLength={140}
                                            onChange={(e) => setMsg(e.target.value)}
                                            value= {msg}
                                            />
                                            <input type="hidden" name="_blacklist" value="fuck, fucker, fag, faggot, kill, murder, rape, shit, nigger, nigga, bitch, b1tch, dick, cock, cunt, die, shoot"></input>
                                        </div>
                        
                        <button type= "submit">Submit</button>
                    </form>
            </div>
    )


}

export default Form