import About from "../Pages/About";
import Contact from "../Pages/Contact";
import Home from "../Pages/Home";
import MsgSent from "../Pages/MsgSent";
import { Routes, Route, useLocation } from 'react-router-dom';
import {AnimatePresence} from 'framer-motion'

const AnimatedRoutes = () => {

    const location = useLocation();

    return (
        <AnimatePresence>
            <Routes location= {location} key= {location.pathname}>
                <Route path="/" element= { <Home /> } />
                <Route path="/about" element= { <About /> } />
                <Route path= "/contact" element= { <Contact /> } />
                <Route path= "/thankyou" element= { <MsgSent /> } />
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes