import "./Nav.css"
import logo from '../imgs/Logo.svg'
import mobilelogo from '../imgs/logomobile.svg'
import { NavLink } from "react-router-dom"

const Nav = () => {

    return (

            <div className="nav">
            
                <img className="desktop" 
                            src= {logo} alt= "logo for Nirvana RV Park"
                            />
                 <img className="mobile" 
                            src= { mobilelogo } alt= "logo for Nirvana RV Park"
                            />
                <div className="links">
                <NavLink
                    to="/"
                    style={({ isActive }) => {
                return {
                textDecoration: isActive ? "underline" : "",
                textDecorationColor: isActive ? "var(--yellow" : ""
                };
                }}>
                        Home
            </NavLink>
            <NavLink
                    to='/about'
                    style={({ isActive }) => {
                    return {
                    textDecoration: isActive ? "underline" : "",
                    textDecorationColor: isActive ? "var(--yellow" : ""
                    };
                    }}>
                        About
                </NavLink>
                <NavLink
                    to='/contact'
                    style={({ isActive }) => {
                    return {
                    textDecoration: isActive ? "underline" : "",
                    textDecorationColor: isActive ? "var(--yellow" : ""
                    };
                    }}>
                        Contact
                </NavLink>
                </div>
            </div>

        
    )

}

export default Nav