import bg2 from '../imgs/bg2webP.webp'
import pic2 from '../imgs/about1.jpg'
import pic2WP from '../imgs/about1.webp'
import { motion } from 'framer-motion'
import Form from '../Components/Form'
import { useEffect } from 'react'

const Contact = () => {


    useEffect(()=>{
        window.scrollTo(0, 0);
      },[])



    return (
        <div id="contact" className="main" style= {{ backgroundImage: `url(${ bg2 })`}} >
            <motion.div className="content"
                            initial={ {opacity: 0 } }
                            animate={ {opacity: 1} }
                            transition={{
                                ease: "easeInOut",
                                duration: 0.2
                            }}
                            exit={ {
                                opacity: 0, 
                                filter: "blur(20px)"
                                } }
                                >

                <picture>
                        <source srcSet= { pic2WP } type ="image/webp" />
                        <img src= { pic2 } alt="decorative" />
                    </picture>

                <Form />

                <span className="contact">
                    <h3>Phone:</h3> (931) 309-2581
                </span>
                <span className="contact">
                    <h3>Address:</h3> 698 Britton Hollow Rd. Pulaski, TN 38478
                </span>
                <br /> 
                <br />
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13041.403381789803!2d-86.9272034!3d35.1977289!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88630193658c1583%3A0xfdb7232418d004ec!2sNirvana%20RV%20Park!5e0!3m2!1sen!2sus!4v1718914983077!5m2!1sen!2sus" 
                                width="600" height="450" style={{border: 0}}  
                                allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                                title="map showing Nirvana RV Park location">

                </iframe>
            </motion.div>
        </div>
    )

}

export default Contact