import bg2 from '../imgs/bg2webP.webp'

const MsgSent = () => {

    return (
        <div className="main" style= {{ backgroundImage: `url(${ bg2 })`}} >
            <div className='content'>
                <h1>Thanks for reaching out! </h1>
                <span>Your message has been submitted.</span>
                <br />
                <span>We appreciate your interest and will get back to you soon!</span>
                <br/>
                <span>Feel free to give us a call or send us a text message if you'd like a faster response.</span>
            </div>

        </div>
    )
}

export default MsgSent